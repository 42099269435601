@media only screen and (min-width:1601px) {
    .credit-last-scan .label .numer-of-risks{
        display: inline-flex;
    }
}
@media only screen and (max-width:1700px) {
.user-assets-protection-layout.d-main-asset-block .asset-block{ flex-grow: 1; }
}
@media only screen and (max-width:1600px) {
    .credit-last-scan{ width: 50%; }
    .credit-more-action-buttons{ width: 100%; padding: 0 0 20px;}
    .credit-more-action-buttons .flex-wrap{
        justify-content: center;
    }
    .d-wrapper > .page-title-block.reverse h2{ width: 100%; text-align: left; }
    .d-wrapper > .page-title-block.reverse{
        flex-direction: column-reverse;
    }
    .page-title-block.reverse .d-meta-links{
        width: 100%;
        display: none;
        justify-content: flex-end;
        margin-bottom: 15px;
    }
}
@media only screen and (max-width:1500px) {
    .page-title-block .d-meta-links{
        top:0;
    }
    .d-block-gauge{ margin: 0 auto; }
    .credit-last-scan{ justify-content: center; }
    .filters-asset-risk-types{ column-gap: 15px; row-gap: 15px; flex-wrap: wrap; }
    .filters-asset-risk-types .filters-asset-risk-type{ width: calc(40% - 30px); flex-grow: 1; }
}
@media only screen and (max-width:1300px) {
    .d-alert-type-details .records{
        display: block;
    }
    .d-block.d-multiple-blocks-small,
    .custom-size-setup-1{ width: 100%; }
    .d-block.d-block.layout-3{ margin-bottom: 20px; }
    .custom-size-setup-1 .d-block{ margin-bottom: 0; }
}
@media only screen and (max-width: 1200px) {
    .billing-settings .dashboard-forms .dashboard-form-fields.col-gap-20 .one-half{ width: 100%; }
}
@media only screen and (max-width: 1120px) {
    #d-header #nav-icon {
        top: 0;
        display: none;
    }
}
@media only screen and (max-width:1024px) {
    .d-block.email-report-page .d-block-header{ row-gap: 20px; }
    .d-block-dropdown.style-1 .custom-select-dropdown-wrap .value-selected{
        width: 100px;
    }
    .d-block.email-report-page .d-block-header,
    .dashboard-forms .dashboard-form-field-group{ flex-wrap: wrap; }
    .d-block.email-report-page .large-score-area{ flex-wrap: wrap; }
    .d-block.email-report-page .large-score-area .large-score{ width: 100%; text-align: center; }
    #asset-risk-type{ display: inline-flex; }
    .large-score-area .credit-last-scan .label .last-scan-text{ display: none; }
    .dashboard-forms .dashboard-form-fields.col-gap-20 .one-half{ width: 100%; }
    .dashboard-forms .dashboard-form-fields.col-gap-20 .one-half:empty{ display: none;}
    .usp-view-report-area.size-1{ column-gap: 15px; }
    #show-risk-alerts-section{ padding: 0 40px 40px;}
}
@media only screen and (max-width: 900px) {
    #d-header #nav-icon {
        top: 0;
        display: block;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .page-title-block .d-meta-links{ right: 0; display: none; }
    
    #d-nav{ display: none; }
    #d-nav.active{
        display: block;
        margin-top: 20px;
    }

    .dashboard-forms .dashboard-form-field.submit-field{ position: absolute; bottom: 0; }
    .dashboard-forms .dashboard-form-field-group:last-of-type{ padding-bottom: 95px; }
    
    .protection-options{ width: 100%; }

    #d-nav .active a{
        background: rgb(217, 217, 217, 0.1);
        border-radius: 4px;
    }
    #main-dashboard.active-notifications .page-title-block:first-of-type{
        position: relative;
        left: 0;
        z-index: 1;
        width: 100%;
        padding: 0;
    }
    #d-header .d-meta-links{
        display: flex;
        position: absolute;
        top: 10px;
        right: 10px;
    }
    #modal-custom-size.show{
        left: 0;
        width: 100%;
    }
    #modal-custom-size.show,
    #modal-notification{top: 80px;}
    #modal-custom-size.show,
    #modal-notification.show{
        height: calc(100% - 80px);
    }
}
@media only screen and (max-width:749px) {
    #d-header .d-meta-links ul{ column-gap: 15px; }
    .user-assets-protection-single{
        flex-wrap: wrap;
        position: relative;
        padding-right: 50px;
    }
    /*
    .usp-view-clean-area{
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -18px;
        flex: 0 0 34px;
    }
    */
    .d-alerts-list-block{
        flex-wrap: nowrap;
        flex-direction: column;
        position: relative;
    }
    .usp-view-clean-area .clean-this-arrow{
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .usp-view-clean-area .clean-this-arrow,
    .d-alerts-list-block .arrow-right{
        position: absolute;
        top: 24px;
        right: 14px;
        width: 30px;
        height: 30px;
        border: 1px solid #000;
        border-radius: 100%;
    }
    .d-alerts-list-block .arrow-right svg{ width: 9px; height: 18px;}

    .d-alerts-list-block .d-alert-type .d-alert-icon{
        position: absolute;
        
    }

    .d-alerts-list-block .d-alert-type{ width: 100%; min-height: 45px; }
    .d-alert-type-details .records{
        display: block;
        position: absolute;
        top:65px;
        left: 16px;
    }
    .d-alerts-list-block .d-alert-type .d-alert-type-details{ padding-left: 57px;}
    .d-alert-time{
        text-align: left; padding-left: 0;
        padding-top: 28px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .page-title-block .d-meta-links .flex-wrap .flex-wrap{ display: none;}

    .d-wrapper > .page-title-block:first-of-type{
        flex-wrap: wrap;
    }
    .d-block.page-title-block h2 {
        text-align: left;
    }
    .usp-view-report-area,
    .usp-view-risk-area{
        width: 100%; flex: 0 0 100%;
        align-items: flex-start;
    }
    .d-block.d-asset-protection-block .form-actions{ flex-grow: 1; }
    .form-custom-dropdown select{ width: 100%; }
    .d-block.d-asset-protection-block .form-actions .form-custom-dropdown{ flex-grow: 1; }
    .form-actions-button{ flex-wrap: wrap; row-gap: 12px;}
    .form-actions-button a{
        width: 100%;
        align-items: center;
        text-align: center;
        justify-content: center;
        min-height: 42px;
    }
    #user-assets-protection-layout .last-scanned{
        text-align: left;
        margin-top: 20px;
        display: flex;
        column-gap: 12px;
        position: absolute;
        bottom: 25px;
        right: 20px;
    }
    .usp-view-report-area.size-1:first-of-type{ flex: 0 0 100%; flex-direction: column; }
    #user-assets-protection-layout .usp-view-risk-area.size-1{
        flex: 100%;
        text-align: left;
        align-items: flex-start;
        justify-content: flex-start;
        display: flex;
    }
    .risk-record-block .risk-score-and-type,
    .help-grid-boxes.flex-wrap-block.col-gap-30 .one-half{ width: 100%; }

    .risk-record-block{ flex-wrap: wrap; }
    .risk-record-block .risk-score-and-type{
        justify-content: flex-start;
        margin-left: 50px;
        margin-top: 5px;
    }
    .d-block.email-report-page h2{ margin-bottom: 20px; }
    .d-block.email-report-page .wrap{ padding: 30px; }

    .d-block.layout-1{
        justify-content: center;
    }
    .d-block h3{ margin-bottom: 15px; }
    .protection-block{ margin-bottom: 18px; }
    .identities-bubbles .identity-bubble{ width: 75px;height: 75px;}
    .risks-total-block .risks-total-large{ font-size: 46px;}
    .user-assets-protection-layout.d-main-asset-block .asset-block{ width: 100%; }
    
    #show-risk-alerts-section{ padding: 0 20px 20px;}
    #show-risk-alerts-section .wrap{ padding: 20px; }
    #show-risk-alerts-section  .usp-view-risk-area.size-1{ flex: 0 0 60%; align-items: flex-start; }
}

@media only screen and (max-width:540px) {

    .d-main-identity-block .identity-block, .d-main-asset-block .asset-block{ min-width: 100%; }
    .d-block.layout-full-width{ row-gap: 20px; }
    
    .d-alerts-block > .wrap > .flex-wrap{ flex-wrap: wrap; row-gap: 12px;}
    .d-block-dropdown{ width: 100%;flex-grow: 1; }
    .d-block.score-graph-5 div.risks-count-limits,
    .d-block.score-graph-5 div.custom-select-dropdown,
    .custom-select-dropdown-wrap .value-selected{ width: 100%; }

    .d-block.layout-2 .wrap .risk-counts{ flex-wrap: wrap; row-gap: 12px;}
    .risks-total-block{ margin-bottom: 20px; }
    .d-block.score-graph-5 div.risks-counts{
        width: 100%;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
    .d-alert-type-details .records{ display: block; }

    .d-main-identity-block .identity-block, .d-main-asset-block .asset-block{ padding-left: 15px; padding-right: 15px; margin-bottom: 0; }
}